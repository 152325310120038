import React,{useContext, useState} from 'react'
import Image from './Image'
import styled from './Dailog.module.less'
import CheckBox from './CheckBox';
import Button from './Button';
import Close from './Close';
import { stateContext } from '@/App';

type propsType={
  show:boolean
}
export default function Dailog({show}:propsType) {
  const [ty, setTy] = useState(false)
  const {phone,setPhone,setShowState} = useContext(stateContext)

  const handleShowChange=()=>{
    if(ty) setShowState(2)
  }

  const handlePhone=()=>{
    const p: string | null = prompt('输入姓名', '135****2552')
    if (p) setPhone(p)
  }
  
  return (
    <div className={styled.container} style={{display:show?'block':'none'}}>
      <div className={styled.mask} />
      <div className={styled.page}>
        <div className={styled.card}>
          <Image />
          <div className={styled.row1}>当前查询内容</div>
          <div className={styled.phone} onClick={handlePhone}>{phone}</div>
          <div className={styled.row3} onClick={()=>setTy(!ty)}>
            <CheckBox width={25} show={ty} />
            <span className={styled.sm}>同意并授权运营商查询本人在疫情期间7天内到访地信息</span>
          </div>
          <Button show={ty} onClick={handleShowChange} />
        </div>
        <div className={styled.close}>
          <Close />
        </div>
      </div>
    </div>
  )
}

