import React from 'react'

export default function Close() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11" stroke="#939798" strokeWidth="2" />
      <path d="M17 17L14 14M8 8L12.5 12.5M8 17L12.5 12.5M17 8L12.5 12.5" stroke="#939798" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
