import React, { FC, useState, useEffect, createContext } from 'react';
//import { useRoutes } from 'react-router-dom'
//import routes from './router/routes';
import now from 'moment'
import { Moment } from 'moment';
import type { MenuProps } from 'antd';
import { Dropdown, Space, Switch } from 'antd';
import './App.less';
// @ts-ignore
import { code, check_in, check_code, trip_bag, gt, rna_check, f_list,home, right } from './static/index'
import TripCard from './views/trip-card/TripCard'

type StateContextType={
  showState:number,
  phone:string,
  setShowState:((s:number)=>void),
  setPhone:((phone:string)=>void)
}

const stateContext = createContext<StateContextType>({showState:0,phone:'135****13597935225',setShowState:()=>{},setPhone:()=>{}})

const App: FC = () => {
  //const outlet = useRoutes(routes)

  const [currtentTime, setCurrtentTime] = useState<Moment>(now())
  const [myname, setMyname] = useState<string | null>('**涛')
  const [mycode, setMycode] = useState<string | null>('***** ******* 4091')
  const [checkTime, setcheckTime] = useState('')
  const [sample, setSample] = useState(false)
  const [showState, setShowState] = useState(0)
  const [phone, setPhone] = useState('135****2552')

  useEffect(() => {
    const t = setInterval(() => setCurrtentTime(now()), 1000)
    setcheckTime(now().subtract(18, 'hours').format('YYYY-MM-DD HH:mm:ss'))
    return () => clearTimeout(t)
  }, []);

  const codeTime: string = currtentTime.format('YYYY-MM-DD HH:mm');
  const sec: string = ("0" + currtentTime.second()).slice(-2)

  const onSampleChange = (e: any) => {
    console.log(e)
    setSample(!sample)
  }

  const items: MenuProps['items'] = [
    {
      label: <div className='menu-item'><Switch title='显示已采样' size="small" onChange={(e) => onSampleChange(e)} checked={sample}></Switch> <span>显示已采样</span></div>,
      key: '0',
    },
  ];

  const handlName = () => {
    const name: string | null = prompt('输入姓名', '***')
    if (name) setMyname(name)
  }

  const handlCode = () => {
    const code: string | null = prompt('输入姓名', '***** ******* ')
    if (code) setMycode(code)
  }

  const handleShowTrip = (b: number): any => {
    return () => setShowState(b)
  }

  return (
    <stateContext.Provider value={{ showState,phone, setShowState,setPhone}}>
      <div className="App">
        <div className='blur' style={{ filter: showState ? 'blur(5px)' : 'none' }}>
          <div className="nav">
            <img src={home} style={{ marginBottom: '3px' }} alt="" />
            <span>湖北健康码</span>
            <Dropdown overlayStyle={{minWidth:'40vw'}} menu={{ items }} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <img src={right} style={{ height: '8vw' }} alt="" />
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="content">
            <div className="map">
              <div className="map-table">
                <div className='title'>
                  <span className="name" style={{ flexGrow: '0' }}>姓名</span>
                  <span style={{ flexGrow: '1', textAlign: 'left' }}>身份证号</span>
                </div>
                <div className="details">
                  <span className='name' onClick={handlName}>{myname}</span>
                  <span className='code' onClick={handlCode}>{mycode}</span>
                </div>
              </div>
            </div>
            <div className="main">
              <div className="card">
                <div className="code">
                  <div style={{ paddingTop: "3vw" }}>
                    <span className='time'>{codeTime + ':'}</span>
                    <span className='sec'>{sec}</span>
                  </div>
                  <img src={code} alt="健康码" style={{ width: "48vw" }} />
                </div>
                <div className='sampling' style={{ display: sample ? 'block' : 'none' }}>
                  <div>
                    <span>核酸</span>
                    <span>已采样</span>
                  </div>
                  <div>
                    {currtentTime.format('YYYY-MM-DD')}
                  </div>
                </div>

                <div className='checktrip' >
                  <img src={check_code} alt="" />
                  <hr />
                </div>
                <div onClick={handleShowTrip(1)} className='trip-card'>
                  <img src={trip_bag} style={{ height: '5.5vw', width: '5.5vw' }} alt="" />
                  <span className='show-card' >出示我的行程卡</span>
                  <img src={gt} style={{ height: '3.5vw' }} alt="" />
                </div>
              </div>
              <div className="md-card">
                <div className="card">
                  <img src={rna_check} alt="" />
                  <div className='checkTime'>{checkTime}</div>
                </div>
                <div className="card">
                  <img src={check_in} alt="" />
                </div>
              </div>

              <div className="card">
                <div className="bottom-card">
                  {f_list.map((obj, index) => {
                    return (
                      <div key={index} className="item">
                        <img src={obj.icon} alt="" />
                        <div>{obj.text}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="foord">
                <div className="tele">
                  <div className='contan'><img src={phone} style={{ height: '4.5vw' }} alt="" /><span>服务热线：12345</span></div>
                  <div>—— 本服务由湖北省新冠肺炎疫情防疫指挥部提供 ——</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <TripCard/>
      </div>
    </stateContext.Provider>

  )

}


export default App;
export {StateContextType,stateContext}