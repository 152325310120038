import React, { useContext, useState } from 'react'
import { CheckCircleFilled } from '@ant-design/icons';

//import {ReactComponent as ToolsLeft} from './static/tools-left'
import { BigTitlePage1, InputPhone, CheckCode } from './static/index'
import { ChinaGuangDian, ChinaMobile, ChinaTeleCom, ChinaTxy, ChinaUniCom, Page1Bottom } from './static/index'
import Dailog from './Dailog/Dailog';
import {stateContext} from '@/App'

export default function Page1() {

  const {showState} = useContext(stateContext)
  const [showDailog, setShowDailog] = useState(false)

  if(showState===1){
    setTimeout(()=>{
      setShowDailog(true)
    },400)
  }

  return (
    <div className='box1'>
      <div className="page1">
        <div className='BigTitlePage1'>
          <img src={BigTitlePage1} style={{ width: '70vw' }} alt='' />
        </div>
        <div className='row2'>
          疫情防控，人人有责
        </div>
        <div className='card'>
          <div className="row">
            <img src={InputPhone} style={{ width: '24px' }} alt="" />
            <span className="col2">请输入手机号</span>
            <span>|</span>
            <span style={{ fontSize: '16px' }}>获取验证码</span>
          </div>
          <div className="row">
            <img src={CheckCode} style={{ width: '25px' }} alt="" />
            <span className="col2">请输入验证码</span>
            <span></span>
            <span></span>
          </div>
          <div className="row" style={{ marginBottom: '0px' }}>
            <CheckCircleFilled style={{ fontSize: '30px' }} />
            <span className="col2 row3" style={{ fontSize: '18.5px' }}>同意并授权运营商查询本人在疫情期间7天内到访地信息</span>
          </div>
          <div className="row" style={{ marginTop: '0px', marginBottom: '0px' }}>
            <button>查询</button>
          </div>
          <div className="row" style={{ marginTop: '0px', marginBottom: '0px' }}><span className='row4'>行程卡使用说明</span></div>
        </div>
        <div className="notes">
          <div className='server'>
            <hr /> <span>本服务联合提供</span>  <hr />
          </div>
          <div className='server-icon'>
            <img src={ChinaTxy} style={{ height: '12px' }} alt="" />
            <img src={ChinaTeleCom} alt="" />
            <img src={ChinaMobile} alt="" />
            <img src={ChinaUniCom} alt="" />
            <img src={ChinaGuangDian} alt="" />
          </div>
          <div className='server-phone'>
            客服热线：<span> 10000 / 10086 / 10010 / 10099</span>
          </div>
        </div>
        <div className='sever-support'>
          <div className="left">
            <img src={Page1Bottom} alt="" />
            <span>防范诈骗 保护你我</span>
          </div>
          <div className='sx'></div>
          <div className="right">
            点击进入
          </div>
        </div>
      </div>
      <Dailog show={showDailog} />
    </div>
  )
}
