import check_in from './check_in.svg'
import nav from './nav.svg'
import map from './map.svg'
import code from './code.svg'
import check_code from './check_code.svg'
import trip_bag from './trip_bag.svg'
import gt from './gt.svg'
import rna_check from './rna_check.svg'
import f1 from './f1.svg'
import f2 from './f2.svg'
import f3 from './f3.svg'
import f4 from './f4.svg'
import f5 from './f5.svg'
import f6 from './f6.svg'
import f7 from './f7.svg'
import f8 from './f8.svg'
import phone from './phone.svg'
import home from './home.svg'
import right from './right.svg'


const f_list=[
    {icon:f1,text:'健康码切换'},
    {icon:f2,text:'核酸地图'},
    {icon:f3,text:'风险地区查询'},
    {icon:f4,text:'各地防疫政策'},
    {icon:f5,text:'疫苗接种'},
    {icon:f6,text:'抗原自测'},
    {icon:f7,text:'申请场所码'},
    {icon:f8,text:'常见问题'},
]

export {nav,check_in,map,code,check_code,trip_bag,gt,rna_check,f_list,phone,home,right}