import React from 'react'
import styled from './Dailog.module.less';

type propsType={
  show:boolean,
  onClick:()=>any
}

export default function Button({show,onClick}:propsType) {
  return (
    <div className={styled.button} onClick={onClick} style={{backgroundColor:show?'#3478F3':'#87AFF8'}}>
      查询
    </div>
  )
}
