import React from 'react'
export default function Image() {

    return (
        <svg width="100%" viewBox="0 0 783 598" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="155" y="90.5103" width="349.704" height="59.164" rx="29.582" transform="rotate(-15 155 90.5103)" fill="#D9D9D9" />
            <rect x="155" y="90.5103" width="349.704" height="59.164" rx="29.582" transform="rotate(-15 155 90.5103)" fill="#D9D9D9" />
            <rect x="155" y="90.5103" width="349.704" height="59.164" rx="29.582" transform="rotate(-15 155 90.5103)" fill="#F5F8FF" />
            <rect x="317" y="157.51" width="349.704" height="59.164" rx="29.582" transform="rotate(-15 317 157.51)" fill="#F5F8FF" />
            <rect x="125" y="501.51" width="420.03" height="59.164" rx="29.582" transform="rotate(-15 125 501.51)" fill="#F5F8FF" />
            <rect x="360" y="540.182" width="421.845" height="59.164" rx="29.582" transform="rotate(-15 360 540.182)" fill="#F5F8FF" />
            <circle cx="68" cy="551" r="30" fill="#F5F8FF" />
            <circle cx="110" cy="134" r="30" fill="#F5F8FF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 182C0 168.745 10.7452 158 24 158H221C234.255 158 245 168.745 245 182V198C238.925 198 234 202.925 234 209C234 215.075 238.925 220 245 220V452C245 465.255 234.255 476 221 476H24C10.7452 476 0 465.255 0 452V220C6.07513 220 11 215.075 11 209C11 202.925 6.07513 198 0 198V182Z" fill="#53A46C" />
            <rect x="24" y="235" width="197" height="218" rx="20" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M218.731 246H190C190 246 184 258 177 258H126H124H122H71C64 258 58 246 58 246H26C29.2936 239.475 36.0569 235 43.8654 235H200.865C208.674 235 215.437 239.475 218.731 246Z" fill="#D9EDE1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M123 395C151.167 395 174 372.167 174 344C174 315.833 151.167 293 123 293C94.8335 293 72 315.833 72 344C72 372.167 94.8335 395 123 395ZM123 388C147.301 388 167 368.301 167 344C167 319.699 147.301 300 123 300C98.6995 300 79 319.699 79 344C79 368.301 98.6995 388 123 388Z" fill="#53A46C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M123 306L93 336V348L118 323V381H128V323L153 348V336L123 306Z" fill="#53A46C" />
            <line x1="27.5" y1="209.5" x2="216.5" y2="209.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeDasharray="2.5 10" />
            <path fillRule="evenodd" clipRule="evenodd" d="M538 182C538 168.745 548.745 158 562 158H759C772.255 158 783 168.745 783 182V198C776.925 198 772 202.925 772 209C772 215.075 776.925 220 783 220V452C783 465.255 772.255 476 759 476H562C548.745 476 538 465.255 538 452V220C544.075 220 549 215.075 549 209C549 202.925 544.075 198 538 198V182Z" fill="#D44A25" />
            <rect x="562" y="235" width="197" height="218" rx="20" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M756.731 246H728C728 246 722 258 715 258H664H662H660H609C602 258 596 246 596 246H564C567.294 239.475 574.057 235 581.865 235H738.865C746.674 235 753.437 239.475 756.731 246Z" fill="#F0B7A8" />
            <path fillRule="evenodd" clipRule="evenodd" d="M661 395C689.167 395 712 372.167 712 344C712 315.833 689.167 293 661 293C632.833 293 610 315.833 610 344C610 372.167 632.833 395 661 395ZM661 388C685.301 388 705 368.301 705 344C705 319.699 685.301 300 661 300C636.699 300 617 319.699 617 344C617 368.301 636.699 388 661 388Z" fill="#D44A25" />
            <path fillRule="evenodd" clipRule="evenodd" d="M661 374.464L661 374.464C640.814 365.964 629.208 345.464 631.226 333.964C634.21 316.964 654.944 313.964 661 327.464C667.055 313.964 687.79 316.964 690.774 333.964C692.792 345.464 681.186 365.964 661 374.464Z" fill="#D54A23" />
            <path fillRule="evenodd" clipRule="evenodd" d="M661 374.464L661 374.464C640.814 365.964 629.208 345.464 631.226 333.964C634.21 316.964 654.944 313.964 661 327.464C667.055 313.964 687.79 316.964 690.774 333.964C692.792 345.464 681.186 365.964 661 374.464Z" fill="#D54A23" />
            <line x1="565.5" y1="209.5" x2="754.5" y2="209.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeDasharray="2.5 10" />
            <path fillRule="evenodd" clipRule="evenodd" d="M269 182C269 168.745 279.745 158 293 158H490C503.255 158 514 168.745 514 182V198C507.925 198 503 202.925 503 209C503 215.075 507.925 220 514 220V452C514 465.255 503.255 476 490 476H293C279.745 476 269 465.255 269 452V220C275.075 220 280 215.075 280 209C280 202.925 275.075 198 269 198V182Z" fill="#EFBD44" />
            <rect x="293" y="235" width="197" height="218" rx="20" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M487.731 246H459C459 246 453 258 446 258H395H393H391H340C333 258 327 246 327 246H295C298.294 239.475 305.057 235 312.865 235H469.865C477.674 235 484.437 239.475 487.731 246Z" fill="#F7DEA1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M392 395C420.167 395 443 372.167 443 344C443 315.833 420.167 293 392 293C363.833 293 341 315.833 341 344C341 372.167 363.833 395 392 395ZM392 388C416.301 388 436 368.301 436 344C436 319.699 416.301 300 392 300C367.699 300 348 319.699 348 344C348 368.301 367.699 388 392 388Z" fill="#EFBD44" />
            <path fillRule="evenodd" clipRule="evenodd" d="M392 371C392 371 369 363.43 369 347.28V322.551C381 324.065 392 317 392 317V371ZM392 371C392 371 415 363.43 415 347.28V322.551C403 324.065 392 317 392 317V371Z" fill="#EFBD42" />
            <line x1="296.5" y1="209.5" x2="485.5" y2="209.5" stroke="white" strokeWidth="5" strokeLinecap="round" strokeDasharray="2.5 10" />
        </svg>
    )
}
