import React from 'react'

type propsType = {
  show: boolean
  width:number
}


export default function CheckBox({ show ,width}: propsType) {


  const cont=(b:boolean)=>
    b ?(<svg width={width} viewBox="0 0 77 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M36.5 0C16.3416 0 0 16.3416 0 36.5C0 56.6584 16.3416 73 36.5 73H40.5C60.6584 73 77 56.6584 77 36.5C77 16.3416 60.6584 0 40.5 0H36.5ZM53.7678 29.7678C54.7441 28.7915 54.7441 27.2085 53.7678 26.2322C52.7915 25.2559 51.2085 25.2559 50.2322 26.2322L33.9439 42.5206L27.7099 36.6762C26.7026 35.7318 25.1205 35.7829 24.1762 36.7901C23.2318 37.7974 23.2829 39.3795 24.2901 40.3238L32.2901 47.8238C33.2745 48.7467 34.8136 48.7219 35.7678 47.7678L53.7678 29.7678Z" fill="#3974F6"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.5 0C16.3416 0 0 16.3416 0 36.5C0 56.6584 16.3416 73 36.5 73H40.5C60.6584 73 77 56.6584 77 36.5C77 16.3416 60.6584 0 40.5 0H36.5ZM53.7678 29.7678C54.7441 28.7915 54.7441 27.2085 53.7678 26.2322C52.7915 25.2559 51.2085 25.2559 50.2322 26.2322L33.9439 42.5206L27.7099 36.6762C26.7026 35.7318 25.1205 35.7829 24.1762 36.7901C23.2318 37.7974 23.2829 39.3795 24.2901 40.3238L32.2901 47.8238C33.2745 48.7467 34.8136 48.7219 35.7678 47.7678L53.7678 29.7678Z" fill="#3974F6"/>
    </svg>
    ):(<svg width={width} viewBox="0 0 77 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="76" height="72" rx="36" stroke="#D9D9D9" />
    </svg>)

  return (
    <div>
      {cont(show)}
    </div>
  )
}
