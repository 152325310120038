import React,{useContext} from 'react'
import './app.less'
import { ToolsLeft, ToolsRight, Title } from './static/index'
import {stateContext} from '@/App'
import Page2 from './Page2'
import Page1 from './Page1'

export default function TripCard() {

  const {showState,setShowState}=useContext(stateContext)

  const handleClose=()=>setShowState(0)

  return (
    <div className='pageshell' style={{ display: showState!==0 ? 'block' : 'none' }}>
      <div className='mask'></div>
      <div className={'page'+(showState===1?' trip_enter':'')}>
        <div className='content'>
          <div className='header'>
            <div className='toolbar'>
              <img src={ToolsLeft} alt="" />
              <img src={ToolsRight} onClick={handleClose} alt="" />
            </div>
            <div className="title">
              <img src={Title} alt="" />
            </div>
          </div>
          {showState===1&&<Page1 />}
          {showState===2&&<Page2 />}
        </div>
      </div>
    </div>
  )
}
