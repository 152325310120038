import React, { useState, useEffect, useContext } from 'react'
import { BigTitle, CardTitle, CenterArrow, FooterLeft, FooterRight } from './static/index'
import now from 'moment';
import './app.less'
import { stateContext } from '@/App';

export default function Page2() {
  const [triptime, setTriptime] = useState('')
  const [adress, setAdress] = useState('湖北省荆门市')
  const {showState,phone,setPhone} = useContext(stateContext)

  useEffect(() => {
    setTriptime(now().format('YYYY-MM-DD HH:mm:ss'))
  }, [])

  const handlePhone = () => {
    const p: string | null = prompt('输入姓名', '135****2552')
    if (p) setPhone(p)
  }

  const handleAdress = () => {
    const p: string | null = prompt('地址', '湖北省荆门市')
    if (p) setAdress(p)
  }

  return (
    <div className={showState===2?'enter':''}>
      <div className="box2">
        <div className='page2'>
          <div className='big_title'>
            <img src={BigTitle} style={{ width: '70vw' }} alt="" />
          </div>
          <div className='row2'>
            疫情防控，人人有责
          </div>
          <div className='card'>
            <img src={CardTitle} style={{ width: '85vw' }} alt=''/>
            <div className='update'>
              <div onClick={handlePhone}>
                {phone}的动态行程卡
              </div>
              <div>
                更新于：{triptime}
              </div>
            </div>
            <div className='arrow'>
              <img src={CenterArrow} alt='' />
            </div>
            <div>
              <hr />
              <div>
                <span>您于前7天内到达或途经：</span>
                <span onClick={handleAdress}>{adress}</span>
              </div>
            </div>
          </div>
          <div className="notes">
            <p>
              结果包含您在前7天内到访的国家（地区）与停留4小时以上的国内城市色卡仅对到访地作提醒，不关联健康状况
            </p>
            <div className='server'>
              <hr /> <span>本服务联合提供</span>  <hr />
            </div>
            <div className='server-icon'>
              <img src={require("./static/xty0.png")} alt="" />
              <img src={require("./static/dianxin0.png")} alt="" />
              <img src={require("./static/yidong0.png")} alt="" />
              <img src={require("./static/liant0.png")} alt="" />
              <img src={require("./static/guangdian0.png")} alt="" />
            </div>
            <div className='server-phone'>客服热线：10000 / 10086 / 10010 / 10099</div>
            <div className='sever-support'>
              <img src={require('./static/ucload.png')} alt="" />
              <span>提供云服务技术支持</span>
            </div>
          </div>
        </div>
      </div>
      <div className="fooder">
        <img src={FooterLeft} alt="" />
        <img src={FooterRight} alt="" />
      </div>
    </div>
  )
}
